@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');
/* LandingPage.css */
.landing {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    min-height: 100vh;
    background-color: #f5f5f5;
    
    background: linear-gradient(135deg, #a7c957, 5%, #f5f5f5);

    font-family: 'Raleway', -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol";    
  }
  
  .landing h1 {
    font-size: 2.5em;
    color: #333;
  }
  
  .landing p {
    font-size: 1.2em;
    color: #666;
  }
  
.top-bar {
    display: flex;     
    justify-content: space-between;     
    align-items: center;   
    width: 100%;
    padding: 10px 0;
}

.top-bar .logo {
    display: flex;    
    align-items: center;    
    font-size: clamp(1rem, 1.5vw, 1rem);
    padding-left: 20px;
}

.top-bar button {
  margin-right: 20px;
}

.top-bar img {
    height: 8vh;    
    margin-right: 1em;    
}

.top-bar a {
    text-decoration: none;
    color: inherit;
    transition: color 0.3s ease; 
    font-size: 1.1em;
}

.top-bar a:hover {
    color: #386641; 
}

.top-bar .menu-items {
    display: flex;
    gap: 30px;
}

.home-page .content {
    display: flex;
    flex-direction: row;
    max-width: 1400px;
    padding: 0 20px;
}

  .main-text {
    flex: 1;
    display: flex;
    justify-content: center;
    flex-direction: column; 
    padding-left: 1em;       
  }

  .main-text h1 {
    font-size: clamp(1.5rem, 4vw, 4rem);
    margin: 0;
  }


  .main-text h1, .main-text p {
    max-width: 90%; 
  }

  .main-image img {
        border-radius: 10px;        
        height: clamp(200px, 30vw, 500px);        
    }

.main-text p {
    font-size: clamp(1.3rem, 2vw, 1.7rem);
    color: #666;
    margin-top: 20px;

}

@media screen and (max-width: 768px) {
    .home-page .content {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

  .main-text h1, .main-text p {
        max-width: 100%;
        text-align: center;
    }

    .home-page .content .main-text {
      align-items: center;
      margin-bottom: 1em;      
    }

    .main-image {
      display: none;
    }

    .content {
      flex-direction: column;
    }    

    .top-bar button {
      display: none;
  
    }
    
}

  .landing button {
    padding: 10px 20px;
    font-size: 1.2em;
    color: #fff;
    background-color: #386641;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 200px;
    transition: background-color 0.3s ease;
  }
  
  .landing button:hover {
    background-color: #a7c957;
  }

  .feature-grid {
    display: flex;
    
    grid-gap: 20px;
    margin-top: 50px;
    gap: 50px;
    flex-flow:  wrap;
    justify-content: center;
  }

  .feature {
    background-color: #fff;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 5px 25px;
    width: 300px;
  }

  .feature h2 {
    font-size: clamp(0.8rem, 1.5rem, 3rem);
  }

  .about-page .content {
    font-size: 2em;
  }

  .about-page .content h1{
    font-size: 1.8em;
  }  

  .footer {
    display: flex;
    justify-content: space-between;
    background-color: #f8f9fa;
    text-align: justify;
    padding: 20px;
    margin-top: 4em;
    left: 0;
    bottom: 0;
  }

  @media (max-width: 1200px) {
    .footer {
      flex-direction: column;
    }
  }

  .footer .left {
    flex: 1;
  }

.footer img {
  height: 18vh;
}

.footer h2 {
  margin-top: 0;
}

  .footer .disclaimer {
    font-size: 1.2em;
    color: #666;
    flex-basis: 60%;
    line-height: 1.4;
  } 

  .footer .left .left-content {
    display: flex;
    align-items: center;
  }
  
  .footer .left .left-info {
    margin-left: 20px;

  }

  .footer p {
    margin: 0.5em 0;
  }